import React from 'react';
import logo from './gnarled-helix-logo-large.png';
import './App.css';

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <img src={logo} className="gn-logo" alt="logo" />
        <h1><span>Gnarled Helix</span><span className="llc-text">LLC</span></h1>
      </header>
      <div className="about">
        <p>
          <b>Gnarled Helix LLC</b> is a small video game studio focused on
          educational games. Our current title, <b>Open Sourcerer</b>,
          focuses on blending platformer action with introductory JavaScript.</p>
      </div>
    </div>
  );
}

export default App;
